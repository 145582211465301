.component-content {
  @include respond-to(mobile-large) {
    .row {
      padding: 0;
      margin: 0;
    }
  }
}
@include respond-to(mobile-large) {
  .row {
    margin: 0;
    padding: 0;
    > * {
      padding: 0;
      margin: 0;
    }
  }
}

.hero-banner {
  .component-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;

    @include respond-to(mobile-large) {
      height: 300px;
    }
  }
  .sc-image-wrapper {
    opacity: 0;
  }
  &.scEnabledChrome {
    &.hero-banner-empty {
      .sc-image-wrapper {
        display: block;
        width: 100%;

        .scEmptyImage {
          max-height: 800px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

.scEmptyImage {
  min-height: 24px !important;
  min-width: 24px !important;
}

.ql-editor {
  &.ql-blank {
    &::before {
      position: static !important;
    }
    p {
      display: none;
    }
  }
}
