@import '@sass/abstracts/vars';

.row.column-splitter {
  margin-left: 0;
  margin-right: 0;
  padding-left: $default-padding / 2;
  padding-right: $default-padding / 2;
  max-width: none;

  > div {
    padding-left: $default-padding;
    padding-right: $default-padding;
  }
}
