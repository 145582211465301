.position-left,
.position-left *,
.rte-align-left,
.rte-align-left * {
  text-align: left !important;
}

.position-right,
.position-right *,
.rte-align-right,
.rte-align-right * {
  text-align: right !important;
}

.position-center,
.position-center *,
.rte-align-center,
.rte-align-center * {
  text-align: center !important;
}

.position-left select {
  direction: ltr !important;
}

.position-right select {
  direction: rtl !important;
}

.position-center select {
  text-align-last: center !important;
}
