@import '@sass/abstracts/vars';
@import '@sass/abstracts/mixins';

.sxa-bordered {
  box-sizing: border-box;
  > .component-content {
    padding: 0;
    @include border-basic();
    border-radius: 5px;
  }
}

.column-splitter,
.row-splitter {
  box-sizing: border-box;
  .sxa-bordered {
    padding: $default-padding !important;
    > .component,
    .scEmptyPlaceholder {
      @include border-basic();
      border-radius: 5px;
    }
  }
}
