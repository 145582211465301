@import '@sass/abstracts/mixins';

.link-button {
  @import '@sass/base/links/';
}
// Promo button
.promo.link-button {
  a {
    @extend .button-default;
  }
}
.link.link-button {
  .is-empty-hint,
  .field-link span,
  a {
    @include default-link-button();
  }
}
