@import '@sass/abstracts/vars';
@import '@sass/abstracts/mixins';

.promo {
  background: $promo-bg;
  padding: 15px;
  overflow: hidden;
  box-sizing: border-box;
  > .component-content {
    position: relative;
    @include clearfix();
    @include border-basic(all, $promo-border);

    > div {
      padding: 5px;
    }
  }
  .zg-height-fix {
    .field-promoicon {
      margin-top: 100px;
    }
  }
  .field-promoicon {
    overflow: hidden;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .field-promolink {
    margin-top: 5px;
    padding-bottom: 10px;
  }
  &.image-full-size {
    img {
      margin-bottom: 10px;
    }
  }
  @import '@sass/variants/promo';
  @import '@sass/base/richtext/richtext';
}
