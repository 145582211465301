@import '@sass/abstracts/vars';
@import 'variables';

.rich-text {
  font-size: $text-size-16;

  @include respond-to(mobile-large) {
    padding: 0 30px 10px 30px;
    font-size: $text-size-14;
  }
}
