$default-padding: 5px;
$default-inputs-padding: 12px 10px;

$extrasmall-margin-bottom: 2px;
$extrasmall-margin: 5px;
$small-margin: 10px;
$middle-margin: 20px;
$large-margin: 30px;
$extralarge-margin: 40px;

$small-padding: 10px;
