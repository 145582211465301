/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  margin: 0;
  letter-spacing: 0.00938em;
}

/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
html {
  font-family: var(--font-pluto-sans);
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--font-pluto-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

h1 {
  font-size: 2.75rem;
  line-height: 3rem;
  font-weight: 500;
  font-family: var(--font-pluto-sans-medium);
}
@media (max-width: 767px) {
  h1 {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  font-family: var(--font-pluto-sans-medium);
}
@media (max-width: 767px) {
  h2 {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
}

h3 {
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 500;
  font-family: var(--font-pluto-sans-medium);
}
@media (max-width: 767px) {
  h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

h4 {
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: 500;
  font-family: var(--font-pluto-sans-medium);
}
@media (max-width: 767px) {
  h4 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}

body * {
  white-space: pre-wrap;
  word-break: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
a {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
  color: #702b91;
  text-decoration: none;
  text-underline-position: under;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: currentColor;
}
a:hover {
  color: #8a53a5;
  text-decoration: underline;
}
a.button__clicked, a:active {
  color: #59107d;
  text-decoration: underline;
}

/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
input {
  color: black;
  font-size: 0.875rem;
  font-family: var(--font-pluto-sans-light);
}
/** 
THEME COLOURS
Please define theme colours of the application here.
*/

/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Button_module_button__44890fc3 {
  background: none;
  color: inherit;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.Button_module_button__44890fc3:active, .Button_module_button__44890fc3:focus {
  outline: none;
}
.Button_module_button__44890fc3:focus-visible {
  box-shadow: #59107d 0 0 0 0.125rem;
}
.Button_module_button__44890fc3 * {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3) p {
  margin-bottom: 0;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3):not(.Button_module_button__text__44890fc3) {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-weight: 500;
  transition: all 300ms ease-out;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__full__44890fc3 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.625rem;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3 {
  color: #666666;
  outline: 0.0625rem solid currentColor;
  cursor: not-allowed;
  background-color: #e5e5e5;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3 * {
  cursor: not-allowed;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3:hover {
  color: #666666;
  background-color: #e5e5e5;
}
.Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3.Button_module_button__clicked__44890fc3, .Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3:focus, .Button_module_button__44890fc3:not(.Button_module_button__custom__44890fc3).Button_module_button__disabled__44890fc3:active {
  color: #666666;
  outline: 0.0625rem solid currentColor;
  background-color: #e5e5e5;
}
.Button_module_button__fullWidth__44890fc3 {
  width: 100%;
}
.Button_module_button__cta__44890fc3 {
  padding: 0.5rem 1rem;
  min-height: 3rem;
  min-width: 9.5rem;
  background-color: #702b91;
  color: #ffffff;
  border-radius: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Button_module_button__cta__44890fc3:hover {
  background-color: #8a53a5;
}
.Button_module_button__cta__44890fc3.Button_module_button__clicked__44890fc3, .Button_module_button__cta__44890fc3:active {
  background-color: #59107d;
  color: #ffffff;
}
.Button_module_button__cta__44890fc3.Button_module_button__outlined__44890fc3:not(.Button_module_button__disabled__44890fc3) {
  color: #702b91;
  background-color: #ffffff;
  outline: 0.0625rem solid #702b91;
}
.Button_module_button__cta__44890fc3.Button_module_button__outlined__44890fc3:not(.Button_module_button__disabled__44890fc3):hover {
  background-color: #e2d5e9;
}
.Button_module_button__cta__44890fc3.Button_module_button__outlined__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3, .Button_module_button__cta__44890fc3.Button_module_button__outlined__44890fc3:not(.Button_module_button__disabled__44890fc3):active {
  color: #59107d;
  background-color: #c6aad3;
  outline: 0.0625rem solid #59107d;
}
.Button_module_button__text__44890fc3:not(.Button_module_button__disabled__44890fc3) {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
  color: #702b91;
  text-decoration: none;
  text-underline-position: under;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: currentColor;
}
.Button_module_button__text__44890fc3:not(.Button_module_button__disabled__44890fc3):hover {
  color: #8a53a5;
  text-decoration: underline;
}
.Button_module_button__text__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3, .Button_module_button__text__44890fc3:not(.Button_module_button__disabled__44890fc3):active {
  color: #59107d;
  text-decoration: underline;
}
.Button_module_button__text__44890fc3.Button_module_button__white__44890fc3 {
  color: #ffffff;
}
.Button_module_button__text__44890fc3.Button_module_button__white__44890fc3:hover {
  color: currentColor;
}
.Button_module_button__text__44890fc3.Button_module_button__white__44890fc3.Button_module_button__clicked__44890fc3, .Button_module_button__text__44890fc3.Button_module_button__white__44890fc3:active {
  color: currentColor;
}
.Button_module_button__text__44890fc3.Button_module_button__text__44890fc3.Button_module_button__disabled__44890fc3 {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
  color: #666666;
  outline: none;
  background-color: transparent;
}
.Button_module_button__text__44890fc3.Button_module_button__text__44890fc3.Button_module_button__disabled__44890fc3:hover {
  background-color: transparent;
}
.Button_module_button__text__44890fc3.Button_module_button__text__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__clicked__44890fc3, .Button_module_button__text__44890fc3.Button_module_button__text__44890fc3.Button_module_button__disabled__44890fc3:focus, .Button_module_button__text__44890fc3.Button_module_button__text__44890fc3.Button_module_button__disabled__44890fc3:active {
  outline: none;
  background-color: transparent;
}
.Button_module_button__secondaryBtn__44890fc3, .Button_module_button__icon__44890fc3:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__noBorder__44890fc3) {
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3), .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):not(.Button_module_button__noBorder__44890fc3) {
  background-color: #ffffff;
  color: #702b91;
  outline: 0.0625rem solid #702b91;
}
.Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3):focus, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):focus:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):focus:not(.Button_module_button__noBorder__44890fc3) {
  outline: 0.0625rem solid #59107d;
}
.Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3):hover, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):hover:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):hover:not(.Button_module_button__noBorder__44890fc3) {
  background-color: #e2d5e9;
}
@media (max-width: 992px) {
  .Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3):focus, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):focus:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):focus:not(.Button_module_button__noBorder__44890fc3) {
    background-color: transparent;
  }
  .Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3):hover, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):hover:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):hover:not(.Button_module_button__noBorder__44890fc3) {
    background-color: transparent;
  }
}
.Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__secondaryBtn__44890fc3:not(.Button_module_button__disabled__44890fc3):active, .Button_module_button__icon__44890fc3:not(.Button_module_button__disabled__44890fc3):active:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__disabled__44890fc3):active:not(.Button_module_button__noBorder__44890fc3) {
  color: #59107d;
  background-color: #c6aad3;
  outline: 0.0625rem solid #59107d;
}
.Button_module_button__formButton__44890fc3 {
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.Button_module_button__formButton__44890fc3:not(.Button_module_button__disabled__44890fc3) {
  background-color: #ffffff;
  color: #702b91;
  outline: 0.0625rem solid #702b91;
}
.Button_module_button__formButton__44890fc3:not(.Button_module_button__disabled__44890fc3):focus {
  outline: 0.0625rem solid #59107d;
}
.Button_module_button__formButton__44890fc3:not(.Button_module_button__disabled__44890fc3):hover {
  background-color: #e2d5e9;
}
.Button_module_button__formButton__44890fc3:not(.Button_module_button__disabled__44890fc3).Button_module_button__clicked__44890fc3, .Button_module_button__formButton__44890fc3:not(.Button_module_button__disabled__44890fc3):active {
  color: #59107d;
  background-color: #c6aad3;
  outline: 0.0625rem solid #59107d;
}
.Button_module_button__icon__44890fc3:not(.Button_module_button__noBorder__44890fc3).Button_module_button__icon__44890fc3:not(.Button_module_button__noBorder__44890fc3), .Button_module_button__navigation__44890fc3:not(.Button_module_button__noBorder__44890fc3):not(.Button_module_button__noBorder__44890fc3) {
  min-height: 0;
  min-height: initial;
  padding: 0;
}
.Button_module_button__icon__44890fc3.Button_module_button__noBorder__44890fc3, .Button_module_button__noBorder__44890fc3.Button_module_button__navigation__44890fc3 {
  background-color: transparent;
  outline: none;
}
.Button_module_button__icon__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3, .Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__navigation__44890fc3 {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
  color: #666666;
  outline: none;
  background-color: transparent;
}
.Button_module_button__icon__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3:hover, .Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__navigation__44890fc3:hover {
  background-color: transparent;
}
.Button_module_button__icon__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__clicked__44890fc3, .Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__clicked__44890fc3.Button_module_button__navigation__44890fc3, .Button_module_button__icon__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3:focus, .Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__navigation__44890fc3:focus, .Button_module_button__icon__44890fc3.Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3:active, .Button_module_button__disabled__44890fc3.Button_module_button__noBorder__44890fc3.Button_module_button__navigation__44890fc3:active {
  outline: none;
  background-color: transparent;
}
.Button_module_button__navigation__44890fc3 {
  border-radius: 50% !important;
}
.Button_module_button__alignAllColors__44890fc3 {
  background-color: #ffffff !important;
  color: currentColor !important;
  outline-color: currentColor !important;
  border-color: currentColor !important;
}
.Button_module_button__alignAllColors__44890fc3 .Button_module_button_contentText__44890fc3 {
  color: currentColor !important;
}
.Button_module_button__44890fc3 > * {
  white-space: nowrap;
}
.Button_module_button__44890fc3 > small {
  white-space: pre-wrap;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.ButtonMenu_module_buttonMenu__68f3d062 {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 0.0625rem solid #d6d6d8;
  background-color: #ffffff;
}
.ButtonMenu_module_buttonMenu__68f3d062 > *:first-child {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.ButtonMenu_module_buttonMenu__68f3d062 > *:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.ButtonMenu_module_buttonMenu__dividers__68f3d062 > *:not(:last-child) {
  border-bottom: 0.0625rem solid #d6d6d8;
}
.ButtonMenu_module_buttonMenu_item__68f3d062 {
  padding: 0.75rem 0.5rem;
}
.ButtonMenu_module_buttonMenu_item__68f3d062.ButtonMenu_module_buttonMenu_item__68f3d062 {
  color: #000000;
  font-weight: 400;
  font-family: var(--font-pluto-sans);
}
.ButtonMenu_module_buttonMenu_header__68f3d062 {
  padding: 1.25rem 1.25rem 0.75rem 0.5rem;
  background-color: #f8f4f9;
  border-bottom: 0.0625rem solid #d6d6d8;
}
.DateRangeCalendar_module_dateRangeCalendar__c2c87c74 {
  display: flex;
  flex-direction: row;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Divider_module_divider__5bdf8744 {
  border: none;
  border-width: 0;
  border-style: solid;
  border-color: #4d4d4d;
  margin: 0;
  opacity: 1;
}
.Divider_module_divider__horizontal__5bdf8744 {
  display: block;
  width: 100%;
}
.Divider_module_divider__vertical__5bdf8744 {
  display: inline-block;
  height: 100%;
}
.Divider_module_divider__inline__5bdf8744 {
  height: 1em;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Dropdown_module_select__90afc7e9 {
  display: inline-block;
  position: relative;
}
.Dropdown_module_select__fullWidth__90afc7e9 {
  width: 100%;
}
.Dropdown_module_select_popup__90afc7e9 {
  z-index: 999;
  min-width: 8rem;
  width: calc(100% + 2.125rem);
  border-width: 0 0.0625rem 0.0625rem 0.0625rem;
  border-color: #702b91;
  border-style: solid;
  border-radius: 0 0 0.5rem 0.5rem !important;
  padding: 0.125rem 0.25rem 0.125rem 0;
  background-color: #ffffff;
  top: calc(100% + 0.8125rem) !important;
  transform: translate(0, 0) !important;
  margin-left: -1.0625rem;
  box-sizing: border-box;
}
.Dropdown_module_select__separateField__90afc7e9 .Dropdown_module_select_popup__90afc7e9 {
  border-top-width: 0.0625rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}
.Dropdown_module_select_group_popup__90afc7e9 {
  z-index: 9998;
  width: calc(100% + 8.5rem);
  padding-top: 3.25rem;
  margin-left: -4.25rem;
  top: 100% !important;
  transform: translate(0, 0) !important;
}
@media (max-width: 993px) {
  .Dropdown_module_select_group_popup__90afc7e9 {
    transform: none !important;
    transform: initial !important;
    will-change: initial !important;
    width: 100%;
  }
}
.Dropdown_module_select__90afc7e9 .Dropdown_module_hidden__90afc7e9 {
  visibility: hidden;
}
.Dropdown_module_select__90afc7e9 .Dropdown_module_noData__90afc7e9 {
  padding: 1rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.DropdownButton_module_selectButtonBox__5f52b7fc {
  position: relative;
}
.DropdownButton_module_selectButtonBox__5f52b7fc .DropdownButton_module_selectButtonInput__5f52b7fc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.DropdownButton_module_selectButtonBox__5f52b7fc .DropdownButton_module_selectButtonInput__5f52b7fc > div {
  width: 100%;
}

.DropdownButton_module_selectButton__5f52b7fc {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.DropdownButton_module_selectButton__5f52b7fc .DropdownButton_module_selectText__5f52b7fc {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.DropdownButton_module_selectButton__5f52b7fc .DropdownButton_module_selectText__5f52b7fc ~ span {
  display: inline-flex;
}
.DropdownButton_module_selectButton__5f52b7fc .DropdownButton_module_selectIconTriangleUp__5f52b7fc {
  transform: rotate(180deg);
}

.DropdownButton_module_smSearchInputBox__5f52b7fc {
  width: calc(100% - 1.5rem) !important;
  padding: 0.75rem 1rem;
  border: 0.0625rem solid #d6d6d8;
  border-radius: 0.5rem;
  position: fixed;
  top: 4.5rem;
  left: 0.75rem;
  z-index: 9999;
  font-size: 1rem;
  box-sizing: border-box;
}

.DropdownButton_module_disabledInput__5f52b7fc input {
  color: #666666;
}

.DropdownButton_module_iconSize__5f52b7fc {
  width: 2rem;
  height: 2rem;
}

.DropdownButton_module_hidden__5f52b7fc {
  visibility: hidden;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.DropdownListbox_module_selectListbox__68b9701e {
  color: #000000;
}
.DropdownListbox_module_selectListbox_list__68b9701e {
  list-style: none;
  margin: 0;
  padding: 0;
}

.DropdownListbox_module_dropdownOption_button__68b9701e {
  width: 100%;
  padding: 1rem;
}
.DropdownListbox_module_dropdownOption_group_button__68b9701e {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
}

.DropdownListbox_module_selectGroupListbox__68b9701e {
  color: #000000;
  margin-top: 0;
}
@media (max-width: 993px) {
  .DropdownListbox_module_selectGroupListbox__68b9701e {
    margin-top: 6.5rem;
  }
}
.DropdownListbox_module_selectGroupListbox__68b9701e > ul {
  padding-right: 0.375rem;
}

.DropdownListbox_module_selectListbox__68b9701e > ul,
.DropdownListbox_module_selectGroupListbox__68b9701e > ul {
  width: 100%;
  height: 0;
  overflow: hidden;
}
.DropdownListbox_module_selectListbox__68b9701e > ul::-webkit-scrollbar,
.DropdownListbox_module_selectGroupListbox__68b9701e > ul::-webkit-scrollbar {
  width: 0.25rem;
}
.DropdownListbox_module_selectListbox__68b9701e > ul::-webkit-scrollbar-thumb,
.DropdownListbox_module_selectGroupListbox__68b9701e > ul::-webkit-scrollbar-thumb {
  background: #702b91;
  border-radius: 0.5rem;
}
.DropdownListbox_module_selectListbox__68b9701e > ul::-webkit-scrollbar-track,
.DropdownListbox_module_selectGroupListbox__68b9701e > ul::-webkit-scrollbar-track {
  background: transparent;
}
.DropdownListbox_module_selectListbox__68b9701e > ul::-webkit-scrollbar-corner,
.DropdownListbox_module_selectGroupListbox__68b9701e > ul::-webkit-scrollbar-corner {
  background: transparent;
}
@supports (-moz-appearance: none) {
  .DropdownListbox_module_selectListbox__68b9701e > ul,
  .DropdownListbox_module_selectGroupListbox__68b9701e > ul {
    padding-right: 0.6875rem;
    scrollbar-color: #702b91 transparent;
  }
}

.DropdownListbox_module_selectListbox__68b9701e > ul {
  max-height: 14.25rem;
}

.DropdownListbox_module_selectGroupListbox__68b9701e > ul {
  max-height: 17.5rem;
}
@media (max-width: 993px) {
  .DropdownListbox_module_selectGroupListbox__68b9701e > ul {
    max-height: calc(100vh - 9.625rem);
  }
}

.DropdownListbox_module_selectListboxOpen__68b9701e > ul {
  height: auto;
  height: initial;
  overflow-y: auto;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 {
  border: 0.0625rem solid #d6d6d8;
  border-radius: 1rem !important;
  background-color: #ffffff;
  position: relative;
  padding: 1rem 0.375rem 0.375rem 0.75rem;
}
@media (max-width: 993px) {
  .TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 {
    padding: 1rem 0.75rem;
  }
}
.TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3::before {
  position: absolute;
  content: "";
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  background: linear-gradient(225deg, #ffffff 50%, transparent 50%);
  border-width: 0.0625rem 0.0625rem 0 0;
  border-color: #d6d6d8;
  border-style: solid;
  transform: translate(-50%, -50%) rotate(-45deg);
  left: 50%;
  top: 0;
}
.TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 .TriangleDropdownListBox_module_triangleDropdownListBoxTitle__4d7c0ee3 {
  display: none !important;
}
.TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 .TriangleDropdownListBox_module_triangleDropdownListBoxTitleCloseIcon__4d7c0ee3 {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
@media (max-width: 993px) {
  .TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 {
    padding: 1.375rem 0.875rem;
    position: fixed;
    width: 100%;
    height: 100%;
    height: 100vh;
    border: none;
    border-radius: 0 !important;
    box-sizing: border-box;
    z-index: 9999;
    top: 0;
    left: 0;
  }
  .TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3::before {
    display: none;
  }
  .TriangleDropdownListBox_module_triangleDropdownListBox__4d7c0ee3 .TriangleDropdownListBox_module_triangleDropdownListBoxTitle__4d7c0ee3 {
    display: flex !important;
    margin-bottom: 1.5rem;
  }
}
.Image_module_image__c9396b72 {
  max-width: 100%;
}

.Image_module_picture__c9396b72 {
  position: relative;
  max-width: 100%;
}
.Image_module_picture__c9396b72 .Image_module_image__c9396b72 {
  width: 100%;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Link_module_link__0586e777 {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
  color: #702b91;
  text-decoration: none;
  text-underline-position: under;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: currentColor;
}
.Link_module_link__0586e777:hover {
  color: #8a53a5;
  text-decoration: underline;
}
.Link_module_link__0586e777.Link_module_button__clicked__0586e777, .Link_module_link__0586e777:active {
  color: #59107d;
  text-decoration: underline;
}
.Link_module_link__0586e777.Link_module_link__white__0586e777 {
  color: #ffffff;
}
.Link_module_link__0586e777.Link_module_link__unbold__0586e777 {
  font-weight: 400;
  font-family: var(--font-pluto-sans);
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.BasicModalTemplate_module_basicModalTemplate__d4ec0e28 {
  width: 100%;
  max-width: 28rem;
  border-radius: 1rem;
  position: relative;
  background-color: white;
  padding: 0;
}
.BasicModalTemplate_module_basicModalTemplate__d4ec0e28:not(.BasicModalTemplate_module_basicModalTemplate__noPadding__d4ec0e28) {
  padding: 2.5rem 1.5rem;
}
.BasicModalTemplate_module_basicModalTemplate__autoWidth__d4ec0e28 {
  width: auto;
  max-width: none;
}
.BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28 {
  height: 100%;
  padding: 0;
  max-height: 80vh;
  overflow-y: auto;
}
.BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28::-webkit-scrollbar {
  width: 0.25rem;
  padding-right: 0.75rem;
}
.BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28::-webkit-scrollbar-thumb {
  background: #702b91;
  border-radius: 0.5rem;
}
.BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28::-webkit-scrollbar-track {
  background: transparent;
}
.BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28::-webkit-scrollbar-corner {
  background: transparent;
}
.BasicModalTemplate_module_basicModalTemplate_overflow_initial__d4ec0e28 {
  overflow-y: visible;
  overflow-y: initial;
}
@media (max-width: 993px) {
  .BasicModalTemplate_module_basicModalTemplate__d4ec0e28 {
    width: 100%;
    max-width: none;
    max-width: initial;
    border-radius: 1rem 1rem 0 0;
    padding: 1.5rem 0.4375rem 1.5rem 0.875rem;
  }
  .BasicModalTemplate_module_basicModalTemplate_child__d4ec0e28 {
    padding-right: 0.4375rem;
  }
}
.BasicModalTemplate_module_basicModalTemplate_content__d4ec0e28 {
  border-radius: 1rem;
  background-color: white;
}
.BasicModalTemplate_module_basicModalTemplate_content__d4ec0e28.BasicModalTemplate_module_basicModalTemplate_content__noTitle__d4ec0e28 .BasicModalTemplate_module_basicModalTemplate_closeButton__d4ec0e28 {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.BasicModalTemplate_module_basicModalTemplate_cardTitle__d4ec0e28 {
  margin-bottom: 1rem;
}
.BasicModalTemplate_module_basicModalTemplate_closeButton__d4ec0e28 {
  font-size: 0.875rem;
  display: block;
  margin-left: auto;
  color: black;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Modal_module_modal__df21e850 {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 999999;
}
@media (max-width: 993px) {
  .Modal_module_modal__df21e850 {
    align-items: flex-end;
  }
}
.Modal_module_modal_backdrop__df21e850 {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.NumberInput_module_numberInput__a841c031 {
  display: inline-block;
}
.NumberInput_module_numberInput_input__a841c031 .MuiInput-input {
  background: none;
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
  border: none;
  outline: none;
  box-shadow: none;
}
.NumberInput_module_numberInput_input__a841c031 .MuiInput-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.NumberInput_module_numberInput_input__a841c031 .MuiInput-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.NumberInput_module_numberInput__primary__a841c031 {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-radius: 2em;
  font-size: 1.125rem;
}
.NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_inputWrapper__a841c031 {
  flex: 1 1 auto;
}
.NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_input__a841c031 {
  padding: 0.5rem 1rem;
}
.NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_minus__a841c031, .NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_plus__a841c031 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2em;
  min-width: 2em;
  background-color: #702b91;
  color: white;
  border-radius: 100%;
  cursor: pointer;
}
.NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_minus__hitLimit__a841c031, .NumberInput_module_numberInput__primary__a841c031 .NumberInput_module_numberInput_plus__hitLimit__a841c031 {
  background-color: #e5e5e5;
  cursor: not-allowed;
  color: #666666;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Slider_module_slider__e6a52ce4 {
  color: #702b91;
  height: 0.25rem;
  width: 100%;
  padding: 1rem 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}
.Slider_module_slider_rail__e6a52ce4 {
  color: #aeb2bb;
  display: block;
  position: absolute;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: currentColor;
  opacity: 0.3;
}
.Slider_module_slider_track__e6a52ce4 {
  display: block;
  position: absolute;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: currentColor;
}
.Slider_module_slider_thumb__e6a52ce4 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: -0.375rem;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  background-color: #702b91;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  transition-duration: 120ms;
  transform-origin: center;
}
.Slider_module_slider__uncoloredTrack__e6a52ce4 .Slider_module_slider_track__e6a52ce4 {
  color: transparent;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.SvgIcon_module_svgIcon__b5a08582 {
  vertical-align: middle;
  font-size: inherit;
}
.SvgIcon_module_svgIcon__b5a08582 path[style*="fill: rgb(0, 0, 0)"] {
  fill: currentColor !important;
}
.SvgIcon_module_svgIcon__textWrapper__b5a08582 {
  display: inline-block;
}
.SvgIcon_module_svgIcon__mobile__b5a08582 {
  font-size: 1.25rem;
}
.SvgIcon_module_svgIcon__desktop__b5a08582 {
  font-size: 1.5rem;
}
.SvgIcon_module_svgIcon__denseLayout__b5a08582 {
  font-size: 2rem;
}
.SvgIcon_module_svgIcon__display__b5a08582 {
  font-size: 2.5rem;
}
.SvgIcon_module_svgIcon__headline__b5a08582 {
  font-size: 4rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Tag_module_smallTag__4a253d8d, .Tag_module_tag_blue__4a253d8d, .Tag_module_tag_red__4a253d8d, .Tag_module_tag_success__4a253d8d, .Tag_module_tag_promo_message__4a253d8d {
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2rem;
}

.Tag_module_tag_promo_tag__4a253d8d {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #feea00;
  color: #702b91;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
}
.Tag_module_tag_promo_message__4a253d8d {
  background-color: #e2d5e9;
  color: #702b91;
}
.Tag_module_tag_success__4a253d8d {
  background-color: #e5f0ee;
  color: #006554;
}
.Tag_module_tag_red__4a253d8d {
  background-color: #fde7ef;
  color: #ed145b;
}
.Tag_module_tag_blue__4a253d8d {
  background-color: #e9f8fb;
  color: #21b9da;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Text_module_text__faa47576 {
  margin: 0;
}
.Text_module_text__ellipsis__faa47576 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Text_module_text__body1__faa47576 {
  font-size: 1.125rem;
}
.Text_module_text__body2__faa47576 {
  font-size: 1rem;
}
.Text_module_text__body2Bold__faa47576 {
  font-size: 1rem;
  font-family: var(--font-pluto-sans-bold);
}
.Text_module_text__body3__faa47576 {
  font-size: 0.875rem;
}
.Text_module_text__body3Light__faa47576 {
  font-size: 0.875rem;
  font-family: var(--font-pluto-sans-light);
}
.Text_module_text__body4__faa47576 {
  font-size: 0.8125rem;
  font-family: var(--font-pluto-sans-light);
}
.Text_module_text__body5__faa47576 {
  font-size: 0.6875rem;
  font-family: var(--font-pluto-sans-light);
}
.Text_module_text__bold__faa47576 {
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
}
.Text_module_text__p1Regular__faa47576 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--font-pluto-sans);
}
.Text_module_text__p1Medium__faa47576 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--font-pluto-sans-medium);
}
.Text_module_text__p2Regular__faa47576 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: var(--font-pluto-sans);
}
.Text_module_text__p2Medium__faa47576 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: var(--font-pluto-sans-medium);
}
.Text_module_text__p3Regular__faa47576 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: var(--font-pluto-sans);
}
.Text_module_text__p3Medium__faa47576 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: var(--font-pluto-sans-medium);
}
.Text_module_text__buttonRegular__faa47576 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.Text_module_text__small__faa47576 {
  font-size: 0.875rem;
}
.Text_module_text__light__faa47576 {
  font-family: var(--font-pluto-sans-light);
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.TextInput_module_textInput__dabf1373 {
  display: inline-block;
}
.TextInput_module_textInput__dabf1373 .MuiInput-root::after, .TextInput_module_textInput__dabf1373 .MuiInput-root::before {
  content: none;
  padding: 0;
}
.TextInput_module_textInput__dabf1373 .MuiInput-input {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  text-overflow: ellipsis;
}
.TextInput_module_textInput__center__dabf1373 .MuiInput-input {
  text-align: center;
}
.TextInput_module_textInput__fullWidth__dabf1373 {
  width: 100%;
}
.TextInput_module_textInput__fullWidth__dabf1373 .TextInput_module_textInput_inputElement__dabf1373 {
  width: 100%;
}
.TextInput_module_textInput_inputWrapper__dabf1373 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.TextInput_module_textInput_input__dabf1373 {
  z-index: 2;
}
.TextInput_module_textInput_input__dabf1373 input.MuiInput-input {
  border-radius: 0.25rem;
}
.TextInput_module_textInput_input__dabf1373 input.MuiInput-input::-moz-placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.TextInput_module_textInput_input__dabf1373 input.MuiInput-input::placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.TextInput_module_textInput_inputElement__dabf1373 {
  position: relative;
}
.TextInput_module_textInput_inputElement__dabf1373 .TextInput_module_textInput_placeholder__dabf1373 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.TextInput_module_textInput__isInEditor__dabf1373 .TextInput_module_textInput_placeholder__dabf1373 {
  z-index: 3;
}
.TextInput_module_textInput__hideInput__dabf1373 .TextInput_module_textInput_input__dabf1373 {
  display: none;
}
.TextInput_module_textInput_iconWrapper__dabf1373 {
  display: flex;
}
.TextInput_module_textInput_visible__dabf1373 {
  width: 1.5rem;
  height: 1.5rem;
  color: #d6d6d8;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Title_module_title__a24e2a9c {
  margin: 0;
  word-break: normal;
  word-break: auto-phrase;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.Title_module_title__center__a24e2a9c {
  text-align: center;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.FieldInput_module_fieldInput__24db2ef4 {
  display: inline-block;
}
.FieldInput_module_fieldInput__error__24db2ef4 .FieldInput_module_fieldInput_errorLabel__24db2ef4 {
  margin-top: 0.25rem;
}
.FieldInput_module_fieldInput__fullWidth__24db2ef4 {
  width: 100%;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.FieldInputGroup_module_fieldInputGroup__d73e7582 {
  display: inline-flex;
  position: relative;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_group__d73e7582 {
  display: inline-table;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_fieldList__d73e7582 {
  display: table-row;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupRow__d73e7582 {
  display: table-row;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupRow__d73e7582 > * {
  display: table-cell;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-color: #d6d6d8;
  border-style: solid;
  border-width: 0;
  border-top-width: 0.0625rem;
  border-bottom-width: 0.0625rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupCell__d73e7582:first-of-type .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-left-width: 0.0625rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupCell__d73e7582:last-of-type .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-right-width: 0.0625rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__horizontal__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__vertical__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-color: #d6d6d8;
  border-style: solid;
  border-width: 0;
  border-left-width: 0.0625rem;
  border-right-width: 0.0625rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__vertical__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__verticalLine__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
  display: none;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__vertical__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
  position: absolute;
  right: 1rem;
  bottom: 0;
  transform: translateY(50%);
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__vertical__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupCell__d73e7582:first-of-type .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-top-width: 0.0625rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__vertical__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupCell__d73e7582:last-of-type .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  border-bottom-width: 0.0625rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  font-family: var(--font-pluto-sans-medium);
  z-index: 2;
}
@media (max-width: 767px) {
  .FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__d73e7582 {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  position: relative;
  min-height: 3.5rem;
  background-color: #ffffff;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__error__d73e7582 {
  border-color: #ed145b;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__focused__d73e7582 {
  border-color: #59107d;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__focused__d73e7582 button:focus,
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__focused__d73e7582 button:active {
  outline: none !important;
  border: none !important;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__focused__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupFieldHelper__d73e7582 {
  visibility: visible;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__hasValue__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupFieldHelper__d73e7582 {
  visibility: visible;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__isInEditor__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupFieldHelper__d73e7582 {
  visibility: visible;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__verticalLine__d73e7582 .FieldInputGroup_module_fieldInputGroup_divider__d73e7582 {
  height: 2rem;
  font-size: 0;
  line-height: 0;
  background-color: #d6d6d8;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__sharpenBottomLeft__d73e7582 {
  border-bottom-left-radius: 0 !important;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__sharpenBottomRight__d73e7582 {
  border-bottom-right-radius: 0 !important;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__disabled__d73e7582 {
  background-color: #e5e5e5;
  color: #666666;
  pointer-events: none;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupField__disabled__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupFieldHelper__d73e7582 {
  background-color: transparent;
}
.FieldInputGroup_module_fieldInputGroup__basic__d73e7582.FieldInputGroup_module_fieldInputGroup__d73e7582 .FieldInputGroup_module_fieldInputGroup_groupFieldHelper__d73e7582 {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0.625rem;
  padding: 0 0.25rem;
  background-color: #ffffff;
  transform: translateY(-50%);
  border-radius: 0.25rem;
}
.FieldInputGroup_module_fieldInputGroup_errorLabel__d73e7582 {
  margin-top: 0.25rem;
}
.FieldInputGroup_module_fieldInputGroup__fullWidth__d73e7582 {
  width: 100%;
}
.FieldInputGroup_module_fieldInputGroup__fullWidth__d73e7582 .FieldInputGroup_module_fieldInputGroup_group__d73e7582 {
  width: 100%;
}
.FieldInputGroup_module_fieldInputGroup_formChild__d73e7582 {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.FieldInputGroup_module_fieldInputGroup_errorIcon__d73e7582 {
  display: inline-block;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.ErrorLabel_module_errorLabel__cfd7db57 {
  display: flex;
  gap: 0.5rem;
}
.ErrorLabel_module_errorLabel_value__cfd7db57 {
  align-self: center;
  text-shadow: 0.0625rem 0.0625rem 0.125rem #ffffff;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Tooltip_module_tooltip__59c9487b {
  color: #702b91;
}
.Tooltip_module_tooltip_icon__59c9487b {
  display: flex;
}
.Tooltip_module_tooltip_content__59c9487b {
  font-size: 0.75rem;
  width: 15.25rem;
  padding: 0.625rem 0.75rem;
  color: #702b91;
  border-radius: 0.5rem;
  background-color: #f4f4f7;
  text-align: left;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}
.Tooltip_module_tooltip_field__59c9487b {
  position: absolute;
  right: 0.3125rem;
  top: 0.3125rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Card_module_card__6dad8f3e {
  background-color: white;
  padding: 1.5rem;
}
.Card_module_card__shadow1__6dad8f3e {
  box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.0392156863);
}
.Card_module_card__shadow2__6dad8f3e {
  box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.0784313725);
}
.Card_module_card__full__6dad8f3e {
  padding: 0;
}
.Card_module_card_title__6dad8f3e {
  margin-bottom: 1.5rem;
}
.Card_module_card__hasTitle__6dad8f3e .Card_module_card_content__6dad8f3e {
  padding-left: 3rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.LinkCx_module_linkCx__f0877d3f {
  display: flex;
  max-width: 66.5625rem;
  gap: 3.75rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.LinkCx_module_linkCx_benefits__f0877d3f {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 767px) {
  .LinkCx_module_linkCx_benefits__f0877d3f {
    flex-direction: column;
    gap: 1.25rem;
  }
}
.LinkCx_module_linkCx_benefit__f0877d3f {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 13.0625rem;
}
@media (max-width: 767px) {
  .LinkCx_module_linkCx_benefit__f0877d3f {
    max-width: none;
    max-width: initial;
  }
}
.LinkCx_module_linkCx_benefitIcon__f0877d3f {
  font-size: 6.0625rem;
}
.LinkCx_module_linkCx_ctaGroup__f0877d3f {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  width: 100%;
  max-width: 27.8125rem;
}
.LinkCx_module_linkCx_successMsg__f0877d3f {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
}
.LinkCx_module_linkCx_successMsgText__f0877d3f {
  display: inline-flex;
  align-items: center;
}
.LinkCx_module_linkCx_accountInfo__f0877d3f {
  display: flex;
  gap: 2.5rem;
}
.CompositeMobileField_module_compositeMobileField__8fd46f84 {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.CompositeMobileField_module_compositeMobileField__8fd46f84 > div:first-child {
  position: static;
  position: initial;
}
.CompositeMobileField_module_compositeMobileField_countryCode__8fd46f84 {
  max-width: 7.5rem;
}
.CompositeMobileField_module_compositeMobileField_mobileNumber__8fd46f84 {
  flex: 1 1 auto;
}
.CompositeMobileField_module_compositeMobileField__fullWidth__8fd46f84 {
  width: 100%;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.SelectedItem_module_selectedItem__8f9305ef {
  padding: 1rem 1.5rem;
  background-color: #f8f4f9;
  border: 0.0625rem solid #702b91;
  border-radius: 1rem;
}
.SelectedItem_module_selectedItem_content__8f9305ef {
  display: flex;
  gap: 0.25rem;
}
.SelectedItem_module_selectedItem_removeBtn__8f9305ef {
  gap: 0.5rem;
  display: flex;
  align-items: center;
}
.SelectedItem_module_selectedItem_itemList__8f9305ef {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1 1 auto;
}

.SelectedItem_module_selectedItemMobile__8f9305ef {
  padding: 1rem;
  background-color: #f8f4f9;
  border: 0.0625rem solid #702b91;
  border-radius: 1rem;
}
.SelectedItem_module_selectedItemMobile_item__8f9305ef {
  margin-left: 1.6875rem;
}

.SelectedItem_module_selectedItemContentTable__8f9305ef {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.SelectedItem_module_selectedItemContentTable_row__8f9305ef {
  display: flex;
  justify-content: space-between;
}
.BigImageBox_module_imageContainer__3caf2d04 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.BigImageBox_module_imageContainer__3caf2d04 .BigImageBox_module_img__3caf2d04 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Drawer_module_drawer__9ffb14ee .MuiDrawer-paper {
  padding: 2.5rem 3rem;
  box-shadow: none;
}
@media (min-width: 768px) and (max-width: 992px) {
  .Drawer_module_drawer__9ffb14ee .MuiDrawer-paper {
    padding: 1.5rem 0.875rem;
  }
}
@media (max-width: 767px) {
  .Drawer_module_drawer__9ffb14ee .MuiDrawer-paper {
    padding: 1.375rem 0.875rem 2.5rem;
  }
}
.Drawer_module_drawer__9ffb14ee .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
@media (min-width: 993px) {
  .Drawer_module_drawer__right__9ffb14ee .MuiDrawer-paper, .Drawer_module_drawer__left__9ffb14ee .MuiDrawer-paper {
    max-width: 46.5rem;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .Drawer_module_drawer__right__9ffb14ee .MuiDrawer-paper, .Drawer_module_drawer__left__9ffb14ee .MuiDrawer-paper {
    width: 100%;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .Drawer_module_drawer__right__9ffb14ee .MuiDrawer-paper, .Drawer_module_drawer__left__9ffb14ee .MuiDrawer-paper {
    width: 100%;
    border-radius: 0;
  }
}
.Drawer_module_drawer__fullWidth__9ffb14ee .MuiDrawer-paper {
  width: 100%;
}
.Drawer_module_drawer__noBorderRadius__9ffb14ee .MuiDrawer-paper {
  border-radius: 0;
}
.Drawer_module_drawer__noPadding__9ffb14ee .MuiDrawer-paper {
  padding: 0;
}
.Drawer_module_drawer__hideBackdrop__9ffb14ee .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0);
}
.Drawer_module_drawer_upper__9ffb14ee {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) and (max-width: 992px) {
  .Drawer_module_drawer_upper__9ffb14ee {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .Drawer_module_drawer_upper__9ffb14ee {
    margin-bottom: 1rem;
  }
}
.Drawer_module_drawer_title__9ffb14ee {
  display: flex;
  align-items: center;
  gap: 1rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Membership_module_formStory__609973e1 {
  background-color: white;
  border: 0.0625rem solid #d6d6d8;
  border-radius: 1rem;
  width: 39rem;
}
@media (max-width: 993px) {
  .Membership_module_formStory__609973e1 {
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
.Membership_module_formStory_form_title__609973e1 {
  background-color: #f6f4f9;
  padding: 0.75rem 2.5rem;
  border-radius: 1rem 1rem 0 0;
  font-weight: bold;
  font-size: 1.375rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 993px) {
  .Membership_module_formStory_form_title__609973e1 {
    padding: 1rem;
    border-radius: 0;
    font-size: 1.125rem;
  }
}
.Membership_module_formStory_form_content__609973e1 {
  display: grid;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 2rem 1.5rem;
}
@media (max-width: 993px) {
  .Membership_module_formStory_form_content__609973e1 {
    padding: 1rem;
    gap: 0.6875rem !important;
  }
}
.Membership_module_formStory_error_label__609973e1 {
  align-self: center;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.MilesPlusCashPaymentOptions_module_milesPlusCashPaymentOptions_desc__8cdc138d {
  margin-bottom: 0.5rem;
}
.MilesPlusCashPaymentOptions_module_milesPlusCashPaymentOptions_titleWrapper__8cdc138d {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.FromToField_module_fromToField__ddff9b92 {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  color: #000;
}
.FromToField_module_fromToField_divider__ddff9b92 {
  display: flex;
  padding: 0.375rem;
}
.FromToField_module_fromToField_subField__ddff9b92 {
  display: flex;
  cursor: pointer;
  font-size: inherit;
  flex: 1 1 auto;
}
.FromToField_module_fromToField_subField__ddff9b92 * {
  cursor: pointer;
}
.FromToField_module_fromToField_inputWrapper__ddff9b92 {
  padding: 0.5rem 0.75rem;
}
.FromToField_module_fromToField_panelWrapper__ddff9b92 {
  width: 100%;
  z-index: 2;
}
.FromToField_module_fromToField_panel__ddff9b92 {
  margin-top: 0.625rem;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0.125rem 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.4);
}
.FromToField_module_fromToField_panelTitle__ddff9b92 {
  margin-bottom: 0.9375rem;
}
.FromToField_module_fromToField_panelOptionList__ddff9b92 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 18.75rem;
  color: #000;
}
@media (min-width: 768px) and (max-width: 992px) {
  .FromToField_module_fromToField_panelOptionList__ddff9b92 {
    max-height: 100%;
  }
}
@media (max-width: 767px) {
  .FromToField_module_fromToField_panelOptionList__ddff9b92 {
    max-height: 100%;
  }
}
.FromToField_module_fromToField_panelOptionGroup__ddff9b92 {
  margin-bottom: 1.25rem;
}
.FromToField_module_fromToField_panelOption__ddff9b92 {
  display: block;
  margin-bottom: 0.25rem;
}

.FromToField_module_arrow__ddff9b92 {
  overflow: hidden;
  position: absolute;
  border: 0.6875rem solid transparent;
  height: 0;
  width: 0;
  border-top-color: #fff;
  border-top-width: 0.625rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PassengerField_module_passengerField__f1782431 {
  position: relative;
  color: #000;
}
.PassengerField_module_passengerField_subField__f1782431 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PassengerField_module_passengerField_input__f1782431 {
  flex: 1 1 auto;
  cursor: pointer;
}
.PassengerField_module_passengerField_input__f1782431 * {
  cursor: pointer;
}
.PassengerField_module_passengerField_iconWrapper__f1782431 {
  display: flex;
  padding: 0.5rem 0.75rem 0.5rem 0;
}
.PassengerField_module_passengerField_panelWrapper__f1782431 {
  width: 100%;
  z-index: 2;
}
.PassengerField_module_passengerField_panel__f1782431 {
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0.125rem 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.4);
}
.PassengerField_module_passengerField_panelTitle__f1782431 {
  margin-bottom: 1rem;
}
.PassengerField_module_passengerField_panelFormRow__f1782431 {
  display: flex;
  align-items: center;
}
.PassengerField_module_passengerField_panelFormRow__f1782431:not(:last-child) {
  margin-bottom: 0.5rem;
}
.PassengerField_module_passengerField_panelFormRow__f1782431 > :nth-child(1) {
  flex: 1 1 auto;
}
.PassengerField_module_passengerField_panelFormRow__f1782431 > :nth-child(2) {
  flex: 0 1 auto;
}
.PassengerField_module_passengerField_panelSubmit__f1782431 {
  margin: 1.5rem auto 0;
}
.TravelDateRangePicker_module_travelDateRangePicker__abc0ee24 {
  background-color: #fff;
  color: #000;
}
.TravelDateRangePicker_module_travelDateRangePicker_inner__abc0ee24 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
}
.TravelDateRangePicker_module_travelDateRangePicker_input__abc0ee24 {
  flex: 1 1 auto;
}
.TravelDateRangePicker_module_travelDateRangePicker_subField__abc0ee24 {
  display: flex;
  cursor: pointer;
}
.TravelDateRangePicker_module_travelDateRangePicker_subField__abc0ee24 * {
  cursor: pointer;
}
.TravelDateRangePicker_module_travelDateRangePicker_divider__abc0ee24 {
  display: flex;
  justify-content: center;
  min-width: 1.25em;
}
.TravelDateRangePicker_module_travelDateRangePicker_panelTitle__abc0ee24 {
  margin-bottom: 0.9375rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.FlightSearch_module_flightSearch__3c2f19cb {
  padding: 1.875rem;
  background-color: #702b91;
  color: #fff;
}
.FlightSearch_module_flightSearch_title__3c2f19cb {
  color: #fff;
}
.FlightSearch_module_flightSearch_titleWrapper__3c2f19cb {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
.FlightSearch_module_flightSearch_tripTabs__3c2f19cb {
  margin-bottom: 0.75rem;
}
.FlightSearch_module_flightSearch_tripList__3c2f19cb {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.FlightSearch_module_flightSearch_formGrid__3c2f19cb {
  display: grid;
  grid-template-columns: 1fr 1fr 17.125rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
@media (min-width: 768px) and (max-width: 992px) {
  .FlightSearch_module_flightSearch_formGrid__3c2f19cb {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .FlightSearch_module_flightSearch_formGrid__3c2f19cb {
    grid-template-columns: 1fr;
  }
}
.FlightSearch_module_flightSearch_submit__3c2f19cb {
  height: 100%;
}
.FlightSearch_module_flightSearch_searchHistory__3c2f19cb {
  display: grid;
  grid-template-columns: 7.5rem 7.5rem 7.5rem;
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.FlightSearch_module_flightSearch_formGridInner__3c2f19cb {
  display: grid;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.FlightSearch_module_flightSearch_tripSelect__3c2f19cb {
  border: #fff 0.125rem solid;
}
.FlightSearch_module_flightSearch_field__3c2f19cb {
  background-color: #fff;
}
.FlightSearch_module_flightSearch_promoCode__3c2f19cb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.FlightSearch_module_flightSearch_promoCode__3c2f19cb .FlightSearch_module_flightSearch_promoCodeLink__3c2f19cb {
  color: #fff;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Footer_module_footer__5c71387f {
  background-color: #f5f5f5;
}
@media (min-width: 768px) and (max-width: 992px) {
  .Footer_module_footer__5c71387f {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 0;
    gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .Footer_module_footer__5c71387f {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 0;
    gap: 1.5rem;
  }
}
@media (min-width: 993px) {
  .Footer_module_footer_upper__5c71387f {
    padding-top: 0.5rem;
  }
}
.Footer_module_footer_navList__5c71387f {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
}
@media (min-width: 993px) {
  .Footer_module_footer_navList__5c71387f {
    flex-direction: row;
    gap: 10.57%;
    padding: 2rem 0 1.5rem;
  }
}
.Footer_module_footer_lowerWrapper__5c71387f {
  margin-top: 2.5rem;
  background-color: #702b91;
}
.Footer_module_footer_lower__5c71387f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  text-align: right;
}
.Footer_module_footer_lower__5c71387f a {
  color: #ffffff;
}
.Footer_module_footer_logo__5c71387f {
  height: 2rem;
  min-width: 7.625rem;
  text-align: left;
}
.Footer_module_footer_communication__5c71387f {
  display: flex;
  align-items: flex-start;
  padding: 2.5rem 0;
  gap: 4.5rem;
}
.Footer_module_footer_newsSubscription__5c71387f {
  display: flex;
  flex-direction: column;
  background-color: #e2d5e9;
  gap: 0.75rem;
  border-radius: 1rem;
  padding: 1.5rem;
}
.Footer_module_footer_subscribeRow__5c71387f {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}
.Footer_module_footer_subscribeRow_flex__5c71387f {
  flex: 1 1;
}
.Footer_module_footer_subscribeRow_flex__5c71387f label {
  display: inline;
  display: initial;
}
.Footer_module_footer_socialShare__5c71387f {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
@media (min-width: 993px) {
  .Footer_module_footer_socialShare__5c71387f {
    align-items: flex-start;
  }
}
.Footer_module_footer_shareChannels__5c71387f {
  display: flex;
  gap: 1rem 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 993px) {
  .Footer_module_footer_shareChannels__5c71387f {
    justify-content: flex-start;
  }
}
.Footer_module_footer_shareChannel__5c71387f {
  display: flex;
  flex-direction: column;
  width: 2rem;
}

.Footer_module_navGroup__5c71387f {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: none;
  max-width: initial;
}
@media (min-width: 993px) {
  .Footer_module_navGroup__5c71387f {
    max-width: 11.3125rem;
  }
}
.Footer_module_navGroup_navLinks__5c71387f {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (min-width: 993px) {
  .Footer_module_navGroup_navLinks__5c71387f {
    gap: 0.25rem;
  }
}
.Footer_module_navGroup_navLinks__5c71387f a {
  font-family: inherit;
  font-weight: normal;
  font-weight: initial;
  text-decoration: none;
  font-size: 0.875rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.AlertMessageBar_module_alertMessageBar__5e6874a5 {
  padding: 0.75rem 0;
  background-color: #f3ece8;
}
.AlertMessageBar_module_alertMessageBar_messageWrapper__5e6874a5 {
  width: 100%;
}
.AlertMessageBar_module_alertMessageBar_messageWrapper__hidden__5e6874a5.AlertMessageBar_module_alertMessageBar_messageWrapper__hidden__5e6874a5 {
  display: none;
}
.AlertMessageBar_module_alertMessageBar_messageList__5e6874a5, .AlertMessageBar_module_alertMessageBar_messageWrapper__5e6874a5 {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
}
@media (min-width: 993px) {
  .AlertMessageBar_module_alertMessageBar_messageList__5e6874a5, .AlertMessageBar_module_alertMessageBar_messageWrapper__5e6874a5 {
    gap: 0.5rem;
    flex-direction: row;
  }
}
.AlertMessageBar_module_alertMessageBar_message__5e6874a5 {
  flex: 1 1 auto;
  display: flex;
  gap: 0.5rem;
  color: #8a3f1e;
}
.AlertMessageBar_module_alertMessageBar_message__5e6874a5 * {
  color: #8a3f1e;
}
.AlertMessageBar_module_alertMessageBar_messageContent__5e6874a5 {
  flex: 1 1 auto;
}
@media (min-width: 993px) {
  .AlertMessageBar_module_alertMessageBar_messageTitle__5e6874a5 {
    max-width: 10.375rem;
  }
}
.AlertMessageBar_module_alertMessageBar_controls__5e6874a5 {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  align-items: flex-start;
}
.AlertMessageBar_module_alertMessageBar_messageControl__5e6874a5 {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Header_module_header__mobile__1cf885bb .Header_module_header_bar__1cf885bb {
  gap: 0;
  padding: 0.75rem 0;
}
.Header_module_header__mobile__1cf885bb .Header_module_header_logo__1cf885bb {
  height: 1.75rem;
}
.Header_module_header__mobile__1cf885bb .Header_module_header_membership__1cf885bb {
  padding: 0;
  gap: 1rem;
}
.Header_module_header__mobile__1cf885bb .Header_module_header_navBtn__1cf885bb {
  height: 2.5rem;
  width: 2.5rem;
}
.Header_module_header_logo__1cf885bb {
  color: #702b91;
  height: 3.125rem;
}
.Header_module_header_barWrapper__1cf885bb {
  border-bottom: 0.0625rem solid #d6d6d8;
  background-color: #ffffff;
}
.Header_module_header_bar__1cf885bb {
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  align-items: center;
  gap: 2.5rem;
  min-height: 6.125rem;
}
.Header_module_header_settings__1cf885bb {
  display: flex;
  gap: 1.5rem;
  margin-left: auto;
}
.Header_module_header_appSettings__1cf885bb {
  display: flex;
  padding: 0.75rem 0;
  gap: 1rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: var(--font-pluto-sans);
  color: #4d4d4d;
}
.Header_module_header_localeField__1cf885bb {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  max-width: 10.625rem;
  min-width: 3.125rem;
}
.Header_module_header_currencyField__1cf885bb {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  max-width: 3.5625rem;
  min-width: 3.125rem;
}
.Header_module_header_membership__1cf885bb {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.Header_module_header_signInMenu__1cf885bb {
  width: 9.0625rem;
}
.Header_module_header_membershipMenu__1cf885bb {
  width: 20.5625rem;
}
.Header_module_header_membershipMenu__1cf885bb .Header_module_header_menuItem__1cf885bb {
  justify-content: flex-start;
}
.Header_module_header_menuHeader__1cf885bb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header_module_navMenu__1cf885bb {
  width: 100vw;
  padding: 1.5rem 0;
  border-bottom: 0.5rem solid #702b91;
  background-color: #ffffff;
  max-height: calc(100vh - 6.125rem);
  overflow-y: auto;
}
.Header_module_navMenu__1cf885bb *:not(svg) {
  font-size: 1rem;
}
.Header_module_navMenu_section__1cf885bb {
  display: flex;
  gap: 1.5rem;
}
.Header_module_navMenu_groupList__1cf885bb {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
  width: 100%;
}
.Header_module_navMenu_groupList__horizontal__1cf885bb {
  flex-direction: row;
}
.Header_module_navMenu_groupList__horizontal__1cf885bb .Header_module_navMenu_group__1cf885bb {
  width: 18.375rem;
}
.Header_module_navMenu_group__1cf885bb {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
  width: 100%;
  flex: 1 1;
}
@media (min-width: 993px) {
  .Header_module_navMenu_groupColumn__1cf885bb {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 1rem;
    flex: 1 1;
  }
  .Header_module_navMenu_groupColumn__1cf885bb > div {
    flex: initial;
  }
}
.Header_module_navMenu_groupLink__1cf885bb {
  color: #000000;
}
.Header_module_navMenu_linkList__1cf885bb {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Header_module_navMenu_linkList__1cf885bb > a {
  font-weight: normal;
  font-weight: initial;
  font-family: var(--font-pluto-sans);
}

.Header_module_drawer__1cf885bb .MuiDrawer-paper {
  max-width: 17.875rem;
}
.Header_module_drawer__1cf885bb .Header_module_header_menuHeader__1cf885bb {
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  gap: 0.625rem;
}
.Header_module_drawer__1cf885bb .Header_module_header_menuHeaderInfo__1cf885bb {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  gap: 0.25rem;
}
.Header_module_drawer_header__1cf885bb {
  padding: 1rem;
  background-color: #702b91;
}
.Header_module_drawer_header__1cf885bb .Header_module_header_logo__1cf885bb {
  height: 2rem;
}
.Header_module_drawer_navHeader__1cf885bb {
  display: flex;
  align-items: center;
  padding: 1rem 0.875rem 1rem 1rem;
  border-bottom: 0.0625rem solid #e5e5e5;
  width: 100%;
  font-weight: 700;
  font-family: var(--font-pluto-sans-bold);
}
.Header_module_drawer_navHeader__1cf885bb > a {
  pointer-events: none;
}
.Header_module_drawer__1cf885bb .Header_module_navMenu_groupLink__1cf885bb {
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  cursor: pointer;
}
.Header_module_drawer__1cf885bb .Header_module_navMenu_groupLink__1cf885bb > a {
  font-family: var(--font-pluto-sans);
  font-weight: normal;
  font-weight: initial;
}
.Header_module_drawer__1cf885bb .Header_module_navMenu_groupLink__noChildren__1cf885bb > a {
  pointer-events: none;
}
.Header_module_drawer__1cf885bb .Header_module_navMenu_link__1cf885bb {
  display: block;
  padding: 1rem 0.875rem 1rem 1rem;
  border-bottom: 0.0625rem solid #e5e5e5;
}
.Header_module_drawer_allSettings__1cf885bb {
  background-color: #f8f4f9;
}
.Header_module_drawer_setting__1cf885bb {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  border-bottom: 0.0625rem solid #d6d6d8;
  gap: 0.25rem;
}
.Header_module_drawer__1cf885bb .Header_module_header_navItem__1cf885bb {
  display: flex;
  width: 100%;
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  border-bottom: 0.0625rem solid #d6d6d8;
  align-items: center;
  justify-content: space-between;
}
.Header_module_drawer__1cf885bb .Header_module_header_navItem__1cf885bb > *:has(> svg) {
  display: inline-flex;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PressRelease_module_pressRelease__c5b3460d {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 2.5rem;
}
.PressRelease_module_pressRelease__c5b3460d > *:first-child {
  flex: 1 1 20%;
}
.PressRelease_module_pressRelease__c5b3460d > *:nth-child(2) {
  flex: 1 1 80%;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Pagination_module_pagination__97fe254e {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  width: 100%;
}
.Pagination_module_pagination__97fe254e .MuiPagination-ul {
  justify-content: center;
}
.Pagination_module_pagination__97fe254e .MuiPaginationItem-root {
  color: #702b91;
  border-color: #702b91;
}
.Pagination_module_pagination__97fe254e .MuiPaginationItem-root:hover {
  background-color: #e2d5e9;
}
.Pagination_module_pagination__97fe254e .Mui-selected {
  border-color: #59107d;
  background-color: #c6aad3;
}
.Pagination_module_pagination__97fe254e .Mui-disabled {
  color: #666666;
  border-color: #666666;
  background-color: #e5e5e5;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.Section_module_section__c0a1557e {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 993px) {
  .Section_module_section__c0a1557e {
    max-width: 81rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .Section_module_section__c0a1557e {
    max-width: 79.75rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
}
@media (max-width: 767px) {
  .Section_module_section__c0a1557e {
    max-width: 79.75rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
}
.Section_module_section__full__c0a1557e {
  padding-left: 0;
  padding-right: 0;
}
.Section_module_section__fullWidth__c0a1557e {
  width: 100%;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
@keyframes LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd {
  0%, 100% {
    opacity: 1;
  }
  20% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.2;
  }
}
@keyframes LoadingBackdrop_module_planeLoader1__284d7acd {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes LoadingBackdrop_module_planeLoaderDefault__284d7acd {
  0% {
    opacity: 0.2;
  }
}
.LoadingBackdrop_module_pageLoader__284d7acd {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.3s linear 0.1s;
  z-index: 1204;
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);
  color: #702b91;
}
.LoadingBackdrop_module_pageLoader__hideBackground__284d7acd {
  background: rgb(255, 255, 255);
}
.LoadingBackdrop_module_pageLoader_loaderWrap__284d7acd {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: none;
  margin: auto;
  padding: 1.5625rem 1.5625rem;
}
.LoadingBackdrop_module_pageLoader_loading__284d7acd {
  display: flex;
  justify-content: center;
  opacity: 1;
  flex-direction: column;
  text-align: center;
}
.LoadingBackdrop_module_pageLoader_loadingPlaneContainer__284d7acd {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.LoadingBackdrop_module_pageLoader_loadingPlane__284d7acd {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0 0.3125rem;
}
.LoadingBackdrop_module_pageLoader_plane1__284d7acd {
  opacity: 1;
  animation: 1s LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd 0s linear infinite;
}
.LoadingBackdrop_module_pageLoader_plane2__284d7acd {
  opacity: 0.2;
  animation: LoadingBackdrop_module_planeLoaderDefault__284d7acd 0.2s linear, 1s LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd 0.2s linear infinite;
}
.LoadingBackdrop_module_pageLoader_plane3__284d7acd {
  opacity: 0.2;
  animation: LoadingBackdrop_module_planeLoaderDefault__284d7acd 0.4s linear, 1s LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd 0.4s linear infinite;
}
.LoadingBackdrop_module_pageLoader_plane4__284d7acd {
  opacity: 0.2;
  animation: LoadingBackdrop_module_planeLoaderDefault__284d7acd 0.6s linear, 1s LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd 0.6s linear infinite;
}
.LoadingBackdrop_module_pageLoader_plane5__284d7acd {
  opacity: 0.2;
  animation: LoadingBackdrop_module_planeLoaderDefault__284d7acd 0.8s linear, 1s LoadingBackdrop_module_planeLoaderRepeatKeyframes__284d7acd 0.8s linear infinite;
}
.DestinationCard_module_imageContainer__f895b48f {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.DestinationCard_module_imageContainer__f895b48f .DestinationCard_module_city__f895b48f {
  font-size: 2rem;
  font: bold;
  color: #fff;
  position: absolute;
  left: 1.25rem;
  bottom: 0.75rem;
  transition: transform 0.3s;
}
.DestinationCard_module_imageContainer__f895b48f .DestinationCard_module_link__f895b48f {
  width: 100%;
  height: 100%;
}
.DestinationCard_module_imageContainer__f895b48f .DestinationCard_module_link__f895b48f::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 100%);
}
.DestinationCard_module_imageContainer__f895b48f .DestinationCard_module_destinationCard__f895b48f {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  transition: border-radius 0.5s, transform 0.5s;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  /**
   * The breakpoint setup can be used in scss.
   * Usage: @include breakpoints(mobile) { font-size: 16px; }
   */
}
.DestinationCard_module_imageContainer__f895b48f:hover .DestinationCard_module_city__f895b48f {
  transform: translateY(-0.75rem);
}
.DestinationCard_module_imageContainer__f895b48f:hover .DestinationCard_module_destinationCard__f895b48f {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  transform: scale(1.04);
}
.RouteMapBar_module_routeMapBar__d50dfdc2 {
  margin-top: 0.875rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}
.RouteMapBar_module_routeMapBar__d50dfdc2 .RouteMapBar_module_routeSegment__d50dfdc2 {
  font-size: 1rem;
}
.RouteMapBar_module_routeMapBar__d50dfdc2 .RouteMapBar_module_routeSegment_path__d50dfdc2 {
  padding-inline: 0.75rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: rgb(211, 205, 205);
}
.RouteMapBar_module_routeMapBar__d50dfdc2 .RouteMapBar_module_routeSegment_hyperLink__d50dfdc2 {
  text-decoration: none;
  color: black;
}
.RouteMapBar_module_routeMapBar__d50dfdc2 .RouteMapBar_module_routeSegment_hyperLink__d50dfdc2:hover {
  text-decoration: underline;
  color: #702b91;
}
.EqualHeightGridCard_module_equalHeightGrid__d5cd46ce {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  /**
   * The breakpoint setup can be used in scss.
   * Usage: @include breakpoints(mobile) { font-size: 16px; }
   */
}
@media (min-width: 640px) and (max-width: 1023px) {
  .EqualHeightGridCard_module_equalHeightGrid__d5cd46ce {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .EqualHeightGridCard_module_equalHeightGrid__d5cd46ce {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.GridCard_module_grid__513afcb1 {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  /**
   * The breakpoint setup can be used in scss.
   * Usage: @include breakpoints(mobile) { font-size: 16px; }
   */
}
@media (min-width: 640px) and (max-width: 1023px) {
  .GridCard_module_grid_left__513afcb1 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .GridCard_module_grid_left__513afcb1 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "a a b" "a a c";
  }
}
@media (min-width: 640px) and (max-width: 1023px) {
  .GridCard_module_grid_right__513afcb1 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .GridCard_module_grid_right__513afcb1 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "b a a" "c a a";
  }
}
@media (min-width: 640px) and (max-width: 1023px) {
  .GridCard_module_grid__513afcb1 .GridCard_module_largeCard__513afcb1 {
    grid-row: span 2;
    grid-column: span 2;
  }
}
@media (min-width: 1024px) {
  .GridCard_module_grid__513afcb1 .GridCard_module_largeCard__513afcb1 {
    grid-area: a;
  }
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.HeroPageTemplate_module_heroPage__8e9a7ba6 {
  padding-bottom: 3.75rem;
}
.HeroPageTemplate_module_heroPage_hkeText__8e9a7ba6 {
  margin-bottom: 1.5rem;
  color: #702b91;
  font-size: 2rem;
}
.HeroPageTemplate_module_heroPage_flex__8e9a7ba6 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  max-width: 77.5rem;
  padding: 0 0.875rem;
  margin: 0 auto;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 1024px) {
  .HeroPageTemplate_module_heroPage_flex__8e9a7ba6 {
    padding: 0 1.5rem;
  }
}
.HeroPageTemplate_module_heroPage_routeFlex__8e9a7ba6 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  max-width: 77.5rem;
  padding: 0 0.875rem;
  margin: 0 auto;
}
.AccordionHKE_module_accordion__032af94e {
  margin-bottom: 1.5rem;
}
.AccordionHKE_module_accordion_header__032af94e {
  background: #f4f4f7;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.AccordionHKE_module_accordion_header__032af94e:hover {
  background: #e3e3e6;
}
.AccordionHKE_module_accordion_trigger__032af94e {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 6.25rem 0.5rem 2.5rem;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
}
.AccordionHKE_module_accordion_trigger__032af94e > h3 {
  margin: 0;
  color: #702b91;
}
.AccordionHKE_module_accordion_trigger_title__032af94e {
  font-size: 1.375rem;
}
.AccordionHKE_module_accordion_trigger_ico__032af94e {
  width: 1.875rem !important;
  height: 1.875rem !important;
  position: absolute !important;
  left: auto !important;
  top: 0.5rem !important;
  inset: 0.75rem;
}
.RichTextArea_module_context_h3__1160dbdf {
  font-size: 1.375rem;
  margin-top: 1.6875rem;
  margin-bottom: 0.625rem;
  color: #702b91;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.4;
}
.RichTextArea_module_context_p__1160dbdf {
  margin-bottom: 0.875rem;
}
@media (max-width: 640px) {
  .RichTextArea_module_context_h3__1160dbdf {
    font-size: 1rem;
  }
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.SelectPassengers_module_selectPassengers__445a5d67 {
  position: relative;
  width: 100%;
}

.SelectPassengers_module_selectPassengersList__445a5d67 {
  padding: 1.375rem 0.875rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 9999;
}

.SelectPassengers_module_selectPassengersListItem__445a5d67 {
  border-bottom: 0.0625rem solid #d6d6d8;
  padding: 0.5rem 0;
}
.SelectPassengers_module_selectPassengersListItem__445a5d67:last-child {
  border-bottom: none;
}

.SelectPassengers_module_numberInputBox__445a5d67 {
  width: 8rem;
}
.SelectPassengers_module_numberInputBox__445a5d67 input {
  text-align: center;
}

.SelectPassengers_module_icon__445a5d67 {
  width: 2rem;
  height: 2rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.SummaryBox_module_item__32f03297, .SummaryBox_module_container_subContainer_border__32f03297, .SummaryBox_module_container_subContainer_item__32f03297 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 1rem;
}

.SummaryBox_module_container__32f03297 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.SummaryBox_module_container_title__32f03297 {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  background-color: #702b91;
  padding: 1rem 0;
}
.SummaryBox_module_container_subContainer__32f03297 {
  display: flex;
  flex-direction: column;
}
.SummaryBox_module_container_subContainer_marginBottom__32f03297 {
  margin-bottom: 0.75rem;
}
.SummaryBox_module_container_subContainer_border__32f03297 {
  border-bottom: dashed 0.0625rem #aeb2bb;
  padding-bottom: 1rem;
}
.SummaryBox_module_container_subContainer_borderBottom__32f03297 {
  border-bottom: dashed 0.0625rem #aeb2bb;
  padding-bottom: 1rem;
}
.SummaryBox_module_container_subContainer_subjectContainer__32f03297 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.SummaryBox_module_container_total__32f03297 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-inline: 1rem;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: dashed 0.125rem black;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentFormSummary_module_summary__2fbe2a12 {
  border: solid 0.0625rem lightgrey;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
.PaymentFormSummary_module_summary_viewDetails__2fbe2a12 {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}
.PaymentFormSummary_module_summary_subTotal__2fbe2a12 {
  width: 100;
  display: flex;
  flex-direction: column;
}
.PaymentFormSummary_module_summary_subTotal_container__2fbe2a12 {
  display: flex;
  flex-direction: column;
}
.PaymentFormSummary_module_summary_subTotal_container_type__2fbe2a12 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1rem;
}
.PaymentFormSummary_module_summary_subTotal_container_item__2fbe2a12 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 1rem;
}
.PaymentFormSummary_module_summary_subTotal_grandTotal__2fbe2a12 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.PaymentFormSummary_module_summary_subTotal_grandTotal_price__2fbe2a12 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
  padding-top: 0.5rem;
  border-top: solid 0.0625rem lightgrey;
}
.PaymentFormSummary_module_summary_generate__2fbe2a12 {
  width: 100%;
  background-color: #702b91;
}
.PaymentFormSummary_module_summary_generate_price__2fbe2a12 {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-inline: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentFormTransactionDetails_module_transactionDetails_removeItem__714f27d8 {
  text-decoration: none;
  color: #702b91;
  font-size: 0.775rem;
  cursor: pointer;
}
.PaymentFormTransactionDetails_module_transactionDetails_removeItem__714f27d8:hover {
  text-decoration: underline;
  color: #7e409c;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentOptionsSelect_module_paymentOptionsSelect__f3b6bdc9 {
  display: flex;
  flex-direction: column;
}
.PaymentOptionsSelect_module_paymentOptionsSelect_paymentTitle__f3b6bdc9 {
  display: flex;
  flex-direction: row;
}
.PaymentOptionsSelect_module_paymentOptionsSelect_paymentMethod__f3b6bdc9 {
  width: "80px";
  height: "80px";
  cursor: "pointer";
}
.PaymentOptionsSelect_module_paymentOptionsSelect_paymentMethod_selected__f3b6bdc9 {
  border: "2px solid";
  border-color: #702b91;
  border-radius: "4px";
  padding-inline: "4px";
}
.DestinationGuidesTemplate_module_container__8441b32a {
  padding: 0 1.5rem;
  max-width: 77.5rem;
  padding: 0 0.875rem 3.75rem 0.875rem;
  margin: 0 auto;
}

.DestinationGuidesTemplate_module_imageContainer__8441b32a {
  padding-bottom: 2rem;
}

.DestinationGuidesTemplate_module_richTextArea__8441b32a {
  padding-bottom: 1rem;
}

.DestinationGuidesTemplate_module_accordionItem__8441b32a:not(:first-child) {
  margin-top: 1.875rem;
}

.DestinationGuidesTemplate_module_context_h1__8441b32a, .DestinationGuidesTemplate_module_context_h2__8441b32a, .DestinationGuidesTemplate_module_context_h3__8441b32a {
  margin-bottom: 1.5rem;
  color: #702b91;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.4;
}
.DestinationGuidesTemplate_module_context_h1__8441b32a {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.DestinationGuidesTemplate_module_context_h2__8441b32a {
  font-size: 1.5rem;
  margin-top: 2.125rem;
}
.DestinationGuidesTemplate_module_context_h3__8441b32a {
  font-size: 1.375rem;
  margin-top: 1.6875rem;
}
.DestinationGuidesTemplate_module_context_p__8441b32a:not(:last-child) {
  margin-bottom: 0.875rem;
}

.DestinationGuidesTemplate_module_questionContainer__8441b32a {
  padding-bottom: 2rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentEmailSendingTemplate_module_paymentRequestForm_title__454ca0c3 {
  font-size: 2rem;
  color: #702b91;
}

.PaymentEmailSendingTemplate_module_text__454ca0c3 {
  margin: 1rem 0;
}

.PaymentEmailSendingTemplate_module_emailBox__454ca0c3 {
  width: 10.625rem;
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: center;
}
.PaymentEmailSendingTemplate_module_emailBox_icon__454ca0c3 {
  padding-right: 0.625rem;
}
.PaymentEmailSendingTemplate_module_emailBox_text__454ca0c3 {
  color: #702b91;
}

.PaymentEmailSendingTemplate_module_underline__454ca0c3 {
  padding: 0.3125rem 0;
  border-bottom: 0.0625rem solid #e0e0e0;
}

.PaymentEmailSendingTemplate_module_buttons__454ca0c3 {
  margin-top: 0.625rem;
  display: flex;
  justify-content: flex-end;
}
.PaymentEmailSendingTemplate_module_buttons_copy__454ca0c3, .PaymentEmailSendingTemplate_module_buttons_sendEmail__454ca0c3 {
  width: 12.5rem;
  height: 2.8125rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PaymentEmailSendingTemplate_module_buttons_copy__454ca0c3 {
  color: #702b91;
  background-color: transparent;
  border: 0.0625rem solid #702b91;
  margin-right: 1.25rem;
}
.PaymentEmailSendingTemplate_module_buttons_sendEmail__454ca0c3 {
  color: #fff;
  background-color: #702b91;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentOptionsPageTemplate_module_paymentOptionsPage_header__9fdc0bd1 {
  font-size: 2rem;
  color: #702b91;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.PaymentRequestFormTemplate_module_paymentRequestForm_title__9e8a7e74 {
  font-size: 2rem;
  color: #702b91;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.VoucherDrawer_module_voucherDrawerContainer__ef4b0126 .MuiDrawer-paper {
  padding: 0;
}

.VoucherDrawer_module_voucherDrawer__ef4b0126 {
  height: 100%;
  width: 100%;
  max-width: 46.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.VoucherDrawer_module_voucherDrawer_header__ef4b0126 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 3rem 1.5rem 3rem;
}
.VoucherDrawer_module_voucherDrawer_title__ef4b0126 {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.VoucherDrawer_module_voucherDrawer_content__ef4b0126 {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  gap: 1.5rem;
}
.VoucherDrawer_module_voucherDrawer_voucherCodeSection__ef4b0126 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.VoucherDrawer_module_voucherDrawer_voucherCodeInput__ef4b0126 {
  display: flex;
  gap: 1rem;
}
.VoucherDrawer_module_voucherDrawer__ef4b0126 hr {
  border: 0.0625rem solid #d6d6d8;
  margin: 0;
}
.VoucherDrawer_module_voucherDrawer_footer__ef4b0126 {
  margin-top: auto;
  padding: 1.5rem 3rem;
  width: 100%;
}
.VoucherDrawer_module_voucherDrawer_amountToDeduceWrapper__ef4b0126 {
  display: flex;
  border-radius: 1rem;
  background-color: #f7f7f7;
  padding: 0.75rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.VoucherDrawer_module_voucherDrawer_amountToDeduce__ef4b0126 {
  display: flex;
  gap: 0.5rem;
}
.VoucherDrawer_module_voucherDrawer_amountToDeduceValue__ef4b0126 {
  display: flex;
  gap: 0.25rem;
}
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/**
 * The breakpoint setup can be used in scss.
 * Usage: @include breakpoints(mobile) { font-size: 16px; }
 */
/** 
THEME COLOURS
Please define theme colours of the application here.
*/
.MilesPlusCashForm_module_milesPlusCashForm_buttons__736199b7 {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.MilesPlusCashForm_module_milesPlusCashForm_buttons__736199b7 button {
  outline: none;
}
.MilesPlusCashForm_module_milesPlusCashForm_buttons__736199b7 button:focus-visible {
  box-shadow: #59107d 0 0 0 0.125rem;
}

.MilesPlusCashForm_module_buttons_error_required__736199b7 button {
  outline-color: #ed145b !important;
}

.MilesPlusCashForm_module_flightAndTravelExtrasField__736199b7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_label__736199b7 {
  display: flex;
  max-width: 19.0625rem;
  flex-direction: column;
  gap: 0.75rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_label_mobile__736199b7 {
  margin-bottom: 1rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_fieldWrapper__736199b7 {
  flex: 1 1 auto;
  max-width: 19.625rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_fieldLabel__736199b7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_milesField__736199b7 {
  max-width: 10.75rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_milesFieldText__736199b7 {
  display: flex;
  align-items: center;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_note__736199b7 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_profile__736199b7 {
  display: flex;
  gap: 1rem;
}
.MilesPlusCashForm_module_flightAndTravelExtrasField_mobile__736199b7 {
  flex-direction: column;
}