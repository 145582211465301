@import '@sass/abstracts/vars';
@import '@sass/abstracts/mixins';

.link-list.list-vertical {
  h3 {
    background: $bg-basic-color;
    width: 100%;
    display: inline-block;
    padding: 3px 5px;
    @include border-basic();
  }
  a {
    border: none;
  }
  li {
    display: block;
    margin-left: $middle-margin;
  }
}
