@import '@sass/abstracts/vars';
@import '@sass/abstracts/mixins';

.content,
.rich-text {
  border: $rich-content-border;
  line-height: 1.5;
  font-size: 12px;
  overflow: hidden;
  * {
    max-width: 100%;
  }
  @import '@sass/base/richtext';
}
.rich-text {
  @import '@sass/variants/rich-text';
}
.content {
  @import '@sass/variants/page-content';
}
