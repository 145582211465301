$text-size-14: 14px;
$text-size-16: 16px;
$text-size-18: 18px;
$text-size-24: 24px;
$text-size-30: 30px;
$text-size-36: 36px;
$text-size-48: 48px;
$text-size-50: 50px;

$main-color: #27272a;
