.highlighted-top {
  background: #ffffff;
  border-top-width: 3px;
  border-top-color: #15909c;
  border-style: solid;
  padding: 25px;
  margin: 0;
}

.highlighted-top h1,
.highlighted-top h2,
.highlighted-top h3,
.highlighted-top h4 {
  margin: 0 0 10px 0;
}

.highlighted-bottom {
  background: #ffffff;
  border-bottom-width: 3px;
  border-bottom-color: #15909c;
  border-style: solid;
  padding: 25px;
  margin: 0;
}

.highlighted-bottom h1,
.highlighted-bottom h2,
.highlighted-bottom h3,
.highlighted-bottom h4 {
  margin: 0 0 10px 0;
}

.highlighted-left {
  background: #ffffff;
  border-left-width: 3px;
  border-left-color: #15909c;
  border-style: solid;
  padding: 25px;
  margin: 0;
}

.highlighted-left h1,
.highlighted-left h2,
.highlighted-left h3,
.highlighted-left h4 {
  margin: 0 0 10px 0;
}

.highlighted-right {
  background: #ffffff;
  border-right-width: 3px;
  border-right-color: #15909c;
  border-style: solid;
  padding: 25px;
  margin: 0;
}

.highlighted-right h1,
.highlighted-right h2,
.highlighted-right h3,
.highlighted-right h4 {
  margin: 0 0 10px 0;
}
